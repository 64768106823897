<template>
    <div class="container_con">
        <el-card>

            <div class="new_search_box flex">
                <div style="margin-top:0px">

                    <span class="mr_20">
                        时间：
                        <el-date-picker v-model="queryParams.time" type="daterange" value-format="YYYY-MM-DD"
                            placeholder="请选择时间" range-separator="至" style="width: 300px;" start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </span>
                    <span class="mr_20">
                        单号：
                        <el-input v-model="queryParams.sale_plan_no" placeholder="请输入报价单号" style="width:240px" />
                    </span>
                    <span class="mr_20">
                        客户名称：
                        <el-select v-model="queryParams.merchant_id" placeholder="请输入客户名称搜索" clearable remote
                            :remote-method="remoteMethod" style="width:240px" filterable>
                            <el-option :label="item.merchant_name" v-for="(item, index) in hwlist" :key="index"
                                :value="item.id"></el-option>
                        </el-select>
                    </span>
                    <span class="mr_20">
                        业务员：
                        <el-select v-model="queryParams.admin_id" placeholder="请选择" style="width: 240px; " filterable>
                            <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code" v-for="(item, index) in ywlist"
                                :key="index" :value="item.id"></el-option>
                        </el-select>
                    </span>
                    <el-button type="primary" @click="handleQuery()">搜索</el-button>
                    <el-button @click="resetQuery()">重置</el-button>

                </div>
            </div>
            <div class="search">
                <div>
                    <el-button type="primary" @click="$router.push('/addSheet')" v-if="have_auth('/priceSheet/add')">+
                        新增</el-button>
                    <el-button type="primary" icon="el-icon-printer" :disabled="multiple" @click="try_print"
                        v-if="have_auth('/priceSheet/print')">打印</el-button>
                    <!-- <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/priceSheet/export')" :loading="is_export" @click="try_export">导出</el-button> -->
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center" />
                    <el-table-column prop="sale_quote_date" label="开票时间" />
                    <el-table-column prop="sale_quote_no" label="单据编号" />
                    <el-table-column prop="admin_name" label="开票人" />
                    <el-table-column prop="merchant_no" label="单位编号" />
                    <el-table-column prop="merchant_name" label="客户单位" />
                    <el-table-column prop="amount" label="整单金额" />
                    <el-table-column fixed="right" label="操作" width="200">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleDetail(scope.row)"
                                v-if="have_auth('/priceSheet/view')">查看</el-button>
                            <el-button type="text" size="small" @click="handleUpdate(scope.row)"
                                v-if="have_auth('/priceSheet/edit')">编辑</el-button>
                            <el-button type="text" size="small" @click="handleDelete(scope.row)"
                                v-if="have_auth('/priceSheet/del')">删除</el-button>
                            <el-button type="text" size="small" v-if="have_auth('/priceSheet/export')" :loading="is_export"
                                @click="try_export(scope.row)">导出</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
        @pagination="getList" />

    <print ref="print" />
</template>

<script>
// 打印
import print from "@/components/print";
export default {
    name: "department",
    components: { print },
    data() {
        return {
            // 列表模板
            loading: false,
            // 查询参数
            queryParams: {
                page: 1,
                size: 10,
                sale_plan_no: '',
                time: ''
            },
            total: 0,
            list: [],
            hwlist: [],
            ywlist: [],
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            is_export: false,
        };
    },
    created() {
        this.queryParams = window[this.$route.path] || {
            page: 1,
            size: 10,
            sale_plan_no: '',
            time: ''
        }
        this.getList();
        this.getOptions();
        this.remoteMethod()
    },
    watch: {
        $route(to, form) {
            window[form.path] = this.queryParams
        },
    },
    methods: {
        getOptions() {
            // 获取业务员 ywlist
            this.$httpGet("/backend/sys.Admin/salesmanList", { size: 10000000 }).then((res) => {
                if (res.status == 200) {
                    this.ywlist = res.data.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        resetQuery() {
            this.queryParams = {
                page: 1,
                size: 10,
                sale_plan_no: '',
                time: ''
            }
            this.getList()
        },
        remoteMethod(keyword) {
            this.$httpGet("/backend/Merchant/merchant_sale", { size: 1000000, title: keyword }).then((res) => {
                if (res.status == 200) {
                    this.hwlist = res.data.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        try_export(row) {
            this.$httpGet("/backend/SaleQuote/export", {id:row.id}).then((res) => {
                if (res.status == 200) {
                    var a = document.createElement('a');
                    let name = res.data.file.split('/')
                    var fileName = name[name.length - 1];
                    a.download = fileName;
                    a.href = this.$http + '/' + res.data.file;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    this.$message.error(res.message);
                }
                this.is_export = false;
            }).catch((err) => {
                console.log(err);
                this.is_export = false;
            });
            // if(this.is_export){return false;}
            // this.is_export = true;
            // let _data={...this.queryParams};
            // _data.startTime=_data.time?_data.time[0]:'';
            // _data.endTime=_data.time?_data.time[1]:'';
            // this.$httpGet("/backend/SaleQuote/export", _data).then((res) => {
            //     if (res.status == 200) {
            //           var a = document.createElement('a');
            //           let name=res.data.file.split('/')
            //           var fileName = name[name.length-1];
            //           a.download = fileName;
            //           a.href = this.$http+'/'+res.data.file;
            //           document.body.appendChild(a);
            //           a.click();
            //           document.body.removeChild(a);
            //     } else {
            //         this.$message.error(res.message);
            //     }
            //     this.is_export = false;
            // }).catch((err) => {
            //     console.log(err);
            //     this.is_export = false;
            // });
        },
        try_print() {
            this.$refs.print.init(this.ids, 8)
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 查看详情
        handleDetail(row) {
            this.$router.push('/sheetDetails?id=' + row.id)
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.$router.push('/addSheet?id=' + row.id)
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const _this = this;
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                _this.$httpGet("/backend/SaleQuote/del", { ids: ids }).then((res) => {
                    if (res.status == 200) {
                        _this.getList();
                        _this.$message.success(res.message);
                    } else {
                        _this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            })
        },
        /** 查询列表 */
        getList() {
            this.loading = true;
            let _data = { ...this.queryParams };
            _data.startTime = _data.time ? _data.time[0] : '';
            _data.endTime = _data.time ? _data.time[1] : '';
            this.$httpGet("/backend/SaleQuote/index", _data).then((res) => {
                if (res.status == 200) {
                    this.loading = false;
                    this.list = res.data.data;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped></style>
